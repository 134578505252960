import {userRoles} from './components/Infrastructure/Authorization/Access';
import {EnabledFlags, Paths} from './common/constants';

export interface MenuType {
	heading?: string;
	name?: string;
	translate?: string;
	path?: string;
	allowedRoles?: any[];
	submenu?: MenuType[];
	icon?: string;
	accessPath?: string[];
	enabledFlagKey?: string;
}

const Menu: MenuType[] = [
	{
		name: 'Device View',
		icon: 'elpro-DeviceView',
		translate: 'sidebar.nav.devicesview',
		allowedRoles: [...userRoles.default],
		submenu: [
			{
				name: 'Dashboard',
				//icon: 'elpro-Dashboard',
				translate: 'sidebar.nav.dashboard',
				path: '/dashboard',
				accessPath: ['devicesview', 'dashboard'],
				allowedRoles: [...userRoles.default],
			},
			{
				name: 'List View',
				//icon: 'elpro-ListView',
				translate: 'sidebar.nav.datagrid',
				path: '/datagrid',
				accessPath: ['devicesview', 'datagrid'],
				allowedRoles: [...userRoles.default],
			},
		],
	},
	{
		name: 'Deviations',
		icon: 'elpro-Deviations2',
		translate: 'sidebar.nav.deviations',
		path: '/deviations',
		allowedRoles: [...userRoles.default],
	},
	{
		name: 'Audit Trail',
		icon: 'elpro-AuditTrail2',
		translate: 'sidebar.nav.auditTrail',
		path: '/auditTrail',
		allowedRoles: [...userRoles.default],
	},
	{
		name: 'Calibration',
		icon: 'fa-solid fa-ruler-combined',
		translate: 'sidebar.nav.calibrations',
		path: '/calibrations',
		allowedRoles: [...userRoles.calibrator],
	},
	{
		name: 'Settings',
		icon: 'elpro-Settings',
		translate: 'sidebar.nav.settings',
		allowedRoles: [...userRoles.default],
		submenu: [
			{
				name: 'Sensor Groups',
				translate: 'sidebar.nav.sensorGroups',
				path: '/sensorGroups',
				accessPath: ['settings', 'sensorGroups'],
			},
			{
				name: 'Limit Profiles',
				translate: 'sidebar.nav.limitProfiles',
				path: '/limitProfiles',
				accessPath: ['settings', 'limitProfiles'],
			},
			{
				name: 'Issue Profiles',
				translate: 'sidebar.nav.issueProfiles',
				path: '/issueProfiles',
				accessPath: ['settings', 'issueProfiles'],
			},
			{
				name: 'Licenses',
				translate: 'sidebar.nav.licenses',
				path: '/licenses',
				accessPath: ['settings', 'licenses'],
			},
			{
				name: 'Invoices & Cards',
				translate: 'sidebar.nav.invoicesAndCards',
				path: '/billing',
				accessPath: ['settings', 'billing'],
			},
			{
				name: 'Organization',
				translate: 'sidebar.nav.organization',
				path: '/organization',
				accessPath: ['settings', 'organization'],
			},
			{
				name: 'User Profile',
				translate: 'sidebar.nav.userProfile',
				path: '/userProfile',
				accessPath: ['settings', 'userProfile'],
			},
			{
				name: 'User Management',
				translate: 'sidebar.nav.userManagement',
				path: '/userManagement',
				accessPath: ['settings', 'userManagement'],
			},
			{
				name: 'Predictive',
				translate: 'sidebar.nav.predictive',
				path: Paths.Settings.PredictiveAnalytics,
				accessPath: ['settings', 'predictive'],
				enabledFlagKey: EnabledFlags.Predictive,
			},
		],
	},
];

export default Menu;
