import {Sensor} from '../Common/Types/Sensor';
import {ValueGetterParams} from 'ag-grid-enterprise';
import {CONSTANTS} from '../Shared/Constants/Constants';
import {DeviceUtils, GetUnitStringFromOutUnit} from '../../common/util';
import dayjs from 'dayjs';
import {FormatAlarmState, TranslateGlobalString} from './ValueFormatters';
import {AlarmState} from '../../common/constants';

type GetterParams = ValueGetterParams<Sensor>;

export function DefaultValueGetter(params: GetterParams): string {
	let value;
	if (params.data instanceof Sensor) {
		const field = params.colDef.field;
		value = params.data[field];
	}

	if (!value || value === '' || (Array.isArray(value) && value.length === 0)) {
		return TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
	}
	return value;
}

export function AlarmStateGetter(params: GetterParams): string {
	const alarmState = params?.data?.GetLimitAlarmState();
	if (!alarmState || alarmState === AlarmState.None) {
		return TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
	}

	return FormatAlarmState(params.data.GetLimitAlarmState());
}

export function DeviceStateGetter(params: GetterParams): string {
	return params?.data?.GetDeviceState().toUpperCase() ?? TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
}

export function MeasurementDateGetter(params: GetterParams): string | dayjs.Dayjs {
	if (!params?.data?.LastMeasurement?.Timestamp) {
		return TranslateGlobalString(CONSTANTS.NO_DATA);
	}
	return params.data.LastMeasurement.Timestamp;
}

export function MeasurementValueGetter(params: GetterParams): string | number {
	const value = params.data?.LastMeasurement?.Value;
	if (!value) {
		return TranslateGlobalString(CONSTANTS.NO_DATA);
	}

	return `${value} ${GetUnitStringFromOutUnit(params.data?.OutUnitsId)}`;
}

export function RunNumberValueGetter(params: GetterParams) {
	if (params?.data && !DeviceUtils.IsLiberoGx(params.data.SerialNumber)) {
		return CONSTANTS.NOT_AVAILABLE;
	}

	return params?.data?.GetRunCount();
}

export function RunStartDateValueGetter(params: GetterParams) {
	if (params?.data && !DeviceUtils.IsLiberoGx(params.data.SerialNumber)) {
		return CONSTANTS.NOT_AVAILABLE;
	}

	return params?.data?.GetStartDate();
}

export function TiltAngleGetter(params: GetterParams): string | number {
	const tiltAngle = params.data?.LastMeasurement?.TiltAngle;
	if (tiltAngle == null) {
		return CONSTANTS.NOT_AVAILABLE;
	}

	return `${Math.round(tiltAngle)}°`;
}
