import {DateTimeUtils} from '../Infrastructure/DateTime/DateTimeUtils';
import * as Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React, {MutableRefObject} from 'react';
import {SensorTiltValue} from '../../common/types/SensorTiltValue';
import {Button} from 'antd';
import {Trans} from 'react-i18next';

NoDataToDisplay(Highcharts);

type TiltGraphProps = {
	tiltValues: SensorTiltValue[];
	filterActive: boolean;
	handleChartSelection: (event: {trigger: any; userMin: any; userMax: any}) => void;
	resetZoom: () => void;
	forwardRef: MutableRefObject<any>;
};

export function TiltGraph(props: TiltGraphProps): React.JSX.Element {
	const chartOptions: Highcharts.Options = {
		credits: {enabled: false},
		exporting: {enabled: false},
		chart: {
			zoomType: 'x',
			height: 400,
			borderRadius: 0,
			spacing: [5, 20, 20, 0],
		},
		time: {
			timezoneOffset: -DateTimeUtils.getCurrentUserTZOffset(),
		},
		title: {text: 'Tilt angle'},
		xAxis: {
			type: 'datetime',
			labels: {
				format: '{value:%d.%b.%Y %H:%M}',
				staggerLines: 2,
				y: 20,
				x: 4,
			},
			events: {
				afterSetExtremes: function (event: {trigger: any; userMin: any; userMax: any}) {
					if (typeof props.handleChartSelection === 'function' && event.trigger) {
						props.handleChartSelection(event);
					}
				}.bind(this),
			},
			maxPadding: 0.03,
		},
		yAxis: {
			labels: {
				format: '{value}°',
			},
			title: {
				text: '',
			},
			min: 0,
			max: 180,
			tickInterval: 45,
		},
		tooltip: {
			valueSuffix: '°',
			xDateFormat: '%d.%b.%Y %H:%M',
		},
		series: [
			{
				animation: false,
				showInLegend: false,
				name: 'Tilt angle',
				data: tiltDataToSeries(props.tiltValues),
			},
		],
	};

	function tiltDataToSeries(tiltData: SensorTiltValue[]) {
		if (!Array.isArray(tiltData)) {
			return [];
		}

		return tiltData.map(t => [DateTimeUtils.dayjsToHighchartsTimestamp(t.Timestamp), t.TiltAngle]);
	}

	return (
		<>
			<div style={{marginBottom: '50px'}}>
				{props.filterActive && (
					<Button style={{float: 'right', marginRight: '20px'}} onClick={props.resetZoom}>
						<Trans i18nKey="global.resetZoom" />
					</Button>
				)}
			</div>
			<ReactHighcharts config={chartOptions} ref={props.forwardRef} />
		</>
	);
}
