import React from 'react';
import {EventDetail, EventDetailType} from '../../common/types';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

export function EventDetailValue(props: {eventDetail: EventDetail}): React.JSX.Element {
	const [t] = useTranslation();
	const eventDetail = props.eventDetail;
	const value = eventDetail.Value;

	if (typeof value === 'object') {
		try {
			if (Array.isArray(value) && eventDetail.EventDetailTypeId === EventDetailType.CalibrationExpiryModules) {
				return (
					<div>
						{value.map((sensor, index) => (
							<span key={index}>
								{sensor.name} {sensor.expiry_date}
								<br />
							</span>
						))}
					</div>
				);
			} else {
				return (
					<div>
						<span>Old value: {value['old_value']}</span>
						<br />
						<span>New value: {value['new_value']}</span>
					</div>
				);
			}
		} catch {}
	}

	switch (eventDetail.EventDetailTypeId) {
		case EventDetailType.ErrorDetails:
		case EventDetailType.EntryReason:
			return t('deviations.reason.' + value.toString());
		case EventDetailType.MessageText:
			return <>{`${eventDetail.Value}`}</>;
		case EventDetailType.SensorLoggingInterval:
			return <>{parseFloat(value.toString()) / 60} min</>;
		case EventDetailType.TimestampDelta:
		case EventDetailType.ProfileLength:
			return <>{DateTimeUtils.formatSecondsAsHumanDuration(parseInt(value.toString()))}</>;
		case EventDetailType.BreachExpectedTimestampUnixUtc:
			return <>{DateTimeUtils.toUserString(dayjs.unix(parseInt(value.toString())))}</>;
	}
	return <>{value.toString()}</>;
}
