import Authentication from '../../components/Infrastructure/Authentication/Authentication';
import {DevUtils} from '../util';
import {request} from '../../components/Infrastructure/Requests/Request';

import * as uuid from 'uuid';
import {
	IAssessmentResult,
	IAssessmentState,
	IGetOrganizationByIdResponse,
	IPrediction,
	IPredictionCalculationResult,
	IPredictionRequestResult,
	IPredictionRequestWithAssessment,
	IPredictionResult,
	ISensorInformation,
	IShippingContainer,
	IShippingLane,
	IUpdateOrganizationByIdRequest,
} from '@elproag/predict';

const LocalhostPrefix: string = DevUtils.UseLocal('PREDICTION_SERVICE') ? 'http://localhost:5036' : '';
const Route: string = `${LocalhostPrefix}/prediction/api/v1`;

export class PredictionService {
	static async GetContainers(orgUuid: string): Promise<IShippingContainer[]> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			return (
				await request({
					url: `${Route}/containers?orgId=${orgUuid}`,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	static async GetLanes(orgUuid: string): Promise<IShippingLane[]> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			return (
				await request({
					url: `${Route}/lanes?orgId=${orgUuid}`,
					method: 'GET',
					token: Authentication.getToken(),
				})
			).data;
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	static async PostPredictions(orgUuid: string, predictionRequest: IPredictionRequestWithAssessment): Promise<IPredictionRequestResult> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			return (
				await request({
					url: `${Route}/predictions?orgId=${orgUuid}`,
					method: 'POST',
					token: Authentication.getToken(),
					data: predictionRequest,
				})
			).data as IPredictionRequestResult;
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	static async GetPredictions(orgUuid: string, sensorRunId: number = null): Promise<IPrediction[]> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		let url = `${Route}/Predictions/?orgId=${orgUuid}`;
		if (sensorRunId) {
			url += `&sensorRunId=${sensorRunId}`;
		}
		try {
			const response = await request({
				url: url,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return response.data as IPrediction[];
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	static async GetPredictionsResult(orgUuid: string, calculationId: string): Promise<IPredictionResult> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			let response = await request({
				url: `${Route}/PredictionResult/${calculationId}/?orgId=${orgUuid}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return (response.data as IPredictionCalculationResult).result;
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	public static async GetAssessmentResults(orgUuid: string, sensorRunId: number): Promise<IAssessmentResult[]> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			const result = await request({
				url: `${Route}/assessments/?orgId=${orgUuid}&sensorRunId=${sensorRunId}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			const assessments = result.data as IAssessmentState[];
			const assessment = assessments[0]; // Currently, we only support one assessment per sensor
			return assessment.assessmentResults;
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}

	public static async GetSensorPredictiveInformation(orgUuid: string, sensorRunId: number): Promise<ISensorInformation> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			const result = await request({
				url: `${Route}/sensors/${sensorRunId}/?orgId=${orgUuid}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return result.data as ISensorInformation;
		} catch (e) {
			if (e.status == 404) {
				return null;
			}

			return Promise.reject(new Error(e));
		}
	}

	public static async GetOrganizationById(orgUuid: string): Promise<IGetOrganizationByIdResponse> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			const result = await request({
				url: `${Route}/organizations/${orgUuid}`,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return result.data as IGetOrganizationByIdResponse;
		} catch (e) {
			if (e.status == 404) {
				return null;
			}

			return Promise.reject(new Error(e));
		}
	}

	public static async UpdateOrganization(orgUuid: string, updateRequest: IUpdateOrganizationByIdRequest): Promise<void> {
		if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');
		try {
			await request({
				url: `${Route}/organizations/${orgUuid}`,
				method: 'PUT',
				token: Authentication.getToken(),
				data: updateRequest,
			});
		} catch (e) {
			return Promise.reject(new Error(e));
		}
	}
}
