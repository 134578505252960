import {Access} from '../../types/DbModel/DbUser';
import {AuthContextType} from '../../context/AuthContext';
import {ElproCloudToken} from '../../types/ElproCloudToken';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';

export class AuthUtils {
	public static DecodeToken(token: string): string {
		const base64Url = token?.split('.')[1];
		const base64 = base64Url?.replace('-', '+').replace('_', '/');
		if (!base64) {
			return undefined;
		}

		return Buffer.from(base64, 'base64').toString();
	}

	public static DecodeTokenToObject(token: string): any {
		if (!token) {
			return undefined;
		}
		return JSON.parse(AuthUtils.DecodeToken(token));
	}

	public static FindAccessElement(path: string[], accesses: Access[]): Access {
		if (!Array.isArray(path) || !Array.isArray(accesses)) {
			return undefined;
		}
		let element: Access = {allow: false, index: -1, key: 'root', path: [], child: accesses};
		path.forEach(subPath => {
			element = element?.child?.find(c => c.key === subPath);
		});
		return element;
	}

	public static GetElproCloudToken(): ElproCloudToken {
		const tokenString = Authentication.getToken();
		if (!tokenString) {
			Authentication.refreshToken();
			return;
		}
		return new ElproCloudToken(tokenString);
	}

	public static IsAllowed(
		authContext: AuthContextType,
		accessSettings: any,
		allowedRoles?: string[],
		accessPath?: string[],
		enabledFlagKey?: string
	): boolean {
		if (allowedRoles && !allowedRoles.some((role: string) => accessSettings.settings?.roles?.includes(role))) return false;
		if (accessPath && !AuthUtils.FindAccessElement(accessPath, accessSettings.access)?.allow) return false;

		try {
			if (enabledFlagKey && !authContext.Organization.Config.global[enabledFlagKey]) return false;
		} catch (_) {
			return false;
		}

		return true;
	}
}
