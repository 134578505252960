import DateTimeUtils from '../../../Infrastructure/DateTime/DateTimeUtils';

export class MeasurementNew {
	constructor(init) {
		this.OrgId = init.org_id;
		this.SensorId = init.sensors_id;

		if (init.timestamp) {
			this.Timestamp = DateTimeUtils.getLocalDateFromUnixTimestamp(init.timestamp);
		} else {
			this.Timestamp = init.tstamp;
		}

		this.Status = init.status;
		this.Value = init.value;
		this.Metadata = init.metadata;
		this.Error = init.error;
		this.Calibration = init.calibration;
		this.TiltAngle = init.tilt_angle;
	}

	public OrgId: number;
	public SensorId: number;
	public Timestamp: Date;
	public Status: number;
	public Value: number;
	public Metadata: object;
	public Error: string | null;
	public Calibration: boolean;
	public TiltAngle: number | null;
}
