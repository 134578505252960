import React from 'react';
import {Table} from 'antd';
import {EventDetail} from '../../common/types';
import {useTranslation} from 'react-i18next';
import {ColumnsType} from 'antd/es/table';
import {GetAuditTrailDetailTranslationKey} from '../../common/helpers/GetAuditTrailDetailTranslationKey';
import {EventDetailValue} from './EventDetailValue';

interface DataType {
	key: React.Key;
	eventDetail: EventDetail;
}

export function EventDetailList(props: {eventDetails: EventDetail[]}): React.JSX.Element {
	const [t] = useTranslation();

	const dataSource: DataType[] = props.eventDetails.map(d => ({
		key: d.Id,
		eventDetail: d,
	}));

	const columns: ColumnsType<DataType> = [
		{
			title: '',
			render: record => t(GetAuditTrailDetailTranslationKey(record.eventDetail.EventDetailTypeId)).toString(),
			width: '20em',
		},
		{
			title: '',
			render: record => <EventDetailValue eventDetail={record.eventDetail}></EventDetailValue>,
		},
	];

	return <Table locale={{emptyText: 'No data'}} pagination={false} showHeader={false} dataSource={dataSource} columns={columns} />;
}
