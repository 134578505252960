import React, { useEffect, useState } from "react";
import { PredictionResultView } from "../Predictive/PredictionResultView";
import { Sensor } from "../Common/Types/Sensor";
import { PredictionService } from "../../common/services";
import { ISensorInformation } from "@elproag/predict";

/**
 * Use this to test you components in isolation at http://localhost:4000/testComponent
 */
export function TestComponent() {
	const [sensorPredictiveInformation, setSensorPredictiveInformation] = useState<ISensorInformation>(undefined);
	useEffect(() => {
		PredictionService.GetSensorPredictiveInformation('ab48a5d6-df49-4a04-8f5a-59cde6137baf', 9691)
			.then(r => setSensorPredictiveInformation(r))
			.catch();
	}, []);
	return (
		<>
			<h1>Component test</h1>
			<PredictionResultView sensorPredictiveInfo={sensorPredictiveInformation} sensor={new Sensor({id: 9691, out_units_id: 2})} />
		</>
	);
}
