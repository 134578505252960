import {MODULE_FAMILIES} from '../../../Shared/Constants/Module';
import {PredictiveTrans} from '../../../Wizard';
import {GetOrganization} from '../../../../common/services/WebserverServicePro/OrganizationService';
import {AuthUtils} from '../../../../common/util';

export type SensorStep = {text: string; disabled?: boolean};

const sensorStepsRbr: SensorStep[] = [
	{
		text: 'sensorWizard.steps.serial_number',
	},
	{
		text: 'sensorWizard.steps.sensor_basics',
	},
	{
		text: 'sensorWizard.steps.alarm_limits',
	},
	{
		text: 'sensorWizard.steps.sensor_issues',
	},
	{
		text: 'sensorWizard.steps.payment',
	},
];

/**
 * Create dynamic steps from LiberoG template
 */
const addSensorWithLiberoGConfigurationTemplateSteps = (configurationTemplate = null, startNewRun = false): SensorStep[] => {
	let steps: SensorStep[] = [
		{
			text: startNewRun ? 'sensorWizard.steps.configuration_template' : 'sensorWizard.steps.serial_number',
		},
		{
			text: 'sensorWizard.steps.configuration_template',
		},
		{
			text: 'sensorWizard.steps.sensor_basics',
		},
		{
			text: 'sensorWizard.steps.alarm_limits',
		},
		{
			text: 'sensorWizard.steps.sensor_issues',
		},
		{
			text: 'sensorWizard.steps.summary',
		},
	];

	if (configurationTemplate !== null) {
		Object.keys(configurationTemplate).forEach(key => {
			if (configurationTemplate[key]['index'] > 0)
				steps.splice(configurationTemplate[key]['index'], 0, {text: configurationTemplate[key]['title']});
		});
	}

	GetOrganization(AuthUtils.GetElproCloudToken().OrgId)
		.then(
			o =>
				o.Config.global.predictiveEnabled &&
				steps.splice(steps.length - 1, 0, {
					text: PredictiveTrans.title,
					disabled: true,
				})
		)
		.catch();
	return steps;
};

/**
 * Create dynamic steps from EcologProG template
 */
const addSensorWithEcologProGConfigurationTemplateSteps = (configurationTemplate = null): SensorStep[] => {
	let steps: SensorStep[] = [
		{
			text: 'sensorWizard.steps.serial_number',
		},
		{
			text: 'sensorWizard.steps.configuration_template',
		},
		{
			text: 'sensorWizard.steps.sensor_basics',
		},
		{
			text: 'sensorWizard.steps.metadatas',
		},
		{
			text: 'sensorWizard.steps.alarm_limits',
		},
		{
			text: 'sensorWizard.steps.sensor_issues',
		},
		{
			text: 'sensorWizard.steps.payment',
		},
	];

	if (configurationTemplate !== null) {
		Object.keys(configurationTemplate).forEach(key => {
			if (configurationTemplate[key]['index'] > 0)
				steps.splice(configurationTemplate[key]['index'], 0, {text: configurationTemplate[key]['title']});
		});
	}

	return steps;
};

export const GetSensorSteps = (hasConfigurationTemplate, configurationTemplate = null, startNewRun = false, templateName = null) => {
	if (hasConfigurationTemplate && templateName !== null) {
		if (templateName === MODULE_FAMILIES.ECOLOG_PRO_G) {
			return addSensorWithEcologProGConfigurationTemplateSteps(configurationTemplate);
		} else if (templateName === MODULE_FAMILIES.LIBERO_G) {
			return addSensorWithLiberoGConfigurationTemplateSteps(configurationTemplate, startNewRun);
		}
	} else {
		//doyang: If there is no template, then it means module is an RBR (Radio Module)
		return sensorStepsRbr;
	}
};
