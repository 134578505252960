import {getOrganizationSetting} from '../../components/Services/UserManagement/UserMangementService';
import {secureToken} from '../../components/Infrastructure/Authorization/Utils';

const RequestString = 'feature-flags';

export class FeatureFlagHelper {
	static GetOrganizationSetting = getOrganizationSetting;

	static async GetFeatureFlags(): Promise<string[]> {
		try {
			const response = await FeatureFlagHelper.GetOrganizationSetting(RequestString);
			const featureFlags = response?.data.setting ?? [];

			secureToken.setFeatureFlagsToken(featureFlags);
			return featureFlags;
		} catch (e) {
			console.error(e);
		}
		return [];
	}

	static async FeatureFlagIsEnabled(featureFlag: string): Promise<boolean> {
		try {
			const featureFlags = await this.GetFeatureFlags();
			return featureFlags.includes(featureFlag);
		} catch (e) {
			// On empty list or rejected promise
			console.error(e);
		}
		return false;
	}

	static async GetFeatureFlagsMap(featureFlags: string[]) {
		const featureFlagMap = new Map<string, boolean>();
		try {
			const enabledFeatureFlags = await this.GetFeatureFlags();
			featureFlags.forEach(f => featureFlagMap.set(f, enabledFeatureFlags.includes(f)));
		} catch (e) {
			// On empty list or rejected promise
			console.error(e);
		}
		return featureFlagMap;
	}
}

export class FeatureFlag {
	static EcologProGxInTransit = 'FF_ECOLOG_PRO_G_TRANSIT';
	static Licensing = 'FF_LICENSING';
}
