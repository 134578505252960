import React from 'react';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import {Tile as OlLayerTile, Vector as OlLayerVector} from 'ol/layer';
import {TileJSON, Vector as OlSourceVector} from 'ol/source';
import {defaults as defaultControls, Attribution} from 'ol/control';
import {fromLonLat} from 'ol/proj';
import C2S from 'canvas2svg';
import {AddClusterFeatures, InitGeoCluster} from './Utils/GeoCluster';
import GeoTooltip from './Utils/GeoTooltip';
import {GeoPolyline} from './Utils/GeoPolyline';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import sensorUtils from '../../Dashboard/Sensor/SensorUtils';
import SensorAnalysisService from '../SensorAnalysisService';
import WithGeoDataSubscription from './WithGeoDataSubscription';
import {RequestLogger} from '../../Infrastructure/Requests/Logger/';
import SystemSettings from '../../Infrastructure/Settings/SystemSettings';

import 'ol/ol.css';

const MapComponent = React.forwardRef((props, ref) => {
	const {sensorId, sensor, dateFrom, dateTo, newGeoPoint} = props;

	const [geoData, setGeoData] = React.useState(null);
	const [olMap, setOlMap] = React.useState(null);
	const [vectorLayer, setVectorLayer] = React.useState(null);
	const [clusterLayer, setClusterLayer] = React.useState(null);
	const mapRef = React.useRef();
	const tooltipRef = React.useRef();

	// Returns the map as SVG
	const getMap = () => {
		let mapCanvasEl = document.createElement('canvas');

		if (olMap === null) {
			return null;
		}

		let size = olMap.getSize();
		mapCanvasEl.width = size[0];
		mapCanvasEl.height = size[1];

		let mapContext = mapCanvasEl.getContext('2d');
		let c2sContext = new C2S(size[0], size[1]);

		Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), canvas => {
			if (canvas.width > 0) {
				const opacity = canvas.parentNode.style.opacity;
				mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

				const transform = canvas.style.transform;
				const matrix = transform
					.match(/^matrix\(([^\(]*)\)$/)[1]
					.split(',')
					.map(Number);

				mapContext.setTransform(...matrix);
				mapContext.drawImage(canvas, 0, 0);
			}
		});

		c2sContext.drawImage(mapCanvasEl, 0, 0);
		return c2sContext.getSerializedSvg(true);
	};

	React.useImperativeHandle(ref, () => ({
		getMap: getMap,
		getGeoData: () => (!geoData ? [] : geoData),
	}));

	// Effect to load and refresh the path when date range changes
	React.useEffect(() => {
		const dateFromIso = DateTimeUtils.getIsoFromUCTWithoutOffset(dateFrom, DateTimeUtils.getCurrentUserTZOffset());
		const dateToIso = DateTimeUtils.getIsoFromUCTWithoutOffset(dateTo, DateTimeUtils.getCurrentUserTZOffset());

		SensorAnalysisService.getGeoDatas(
			sensorId,
			dateFromIso,
			dateToIso,
			RequestLogger.createLogData('geo-data', 'load-geo-data', 'onLoad')
		).then(({data}) => {
			data.forEach(d => {
				d.tstamp = DateTimeUtils.utcOffset_date_dep(d.tstamp).format('DD.MMM.YYYY HH:mm');
				// moment.utc(d.tstamp).utcOffset(userOffset).format('DD.MMM.YYYY HH:mm');

				if (!d.mesurement) d.mesurement = {};

				// used for csv export only
				d.mesurement.tstampOriginal = DateTimeUtils.utcOffset_date_dep(d.mesurement.tstamp);
				//moment.utc(d.mesurement.tstamp).utcOffset(userOffset);

				d.mesurement.tstamp = !d.mesurement.tstamp
					? 'n.a.'
					: DateTimeUtils.utcOffset_date_dep(d.mesurement.tstamp).format('DD.MMM.YYYY HH:mm'); //moment.utc(d.mesurement.tstamp).utcOffset(userOffset).format('DD.MMM.YYYY HH:mm');
				d.mesurement.value = !d.mesurement.value
					? 'n.a.'
					: `${sensorUtils.convertTemperature(d.mesurement.value, sensor.out_units_id)} ${sensorUtils.getSensorUnitType(
							sensor.out_units_id
					  )}`;
			});

			setGeoData(data);
		});
	}, [sensorId, dateFrom, dateTo]);

	// Effect to init OSM map & The Tooltip
	React.useLayoutEffect(() => {
		const shouldInitMap = mapRef.current !== null && olMap === null;
		if (mapRef.current === null) setOlMap(null);
		if (!shouldInitMap) return;

		const endPoint = geoData !== null && geoData.length > 0 ? geoData[geoData.length - 1] : {long: 0, lat: 0};

		// SetTimeout: To run when the current stack is cleared
		// requestAnimationFrame: To run before the next render
		setTimeout(() =>
			requestAnimationFrame(() => {
				mapRef.current.innerHTML = '';
				const attribution = new Attribution({collapsible: true});
				const mapUrl = SystemSettings.getConfig().map_link;
				const map = new OlMap({
					target: mapRef.current,
					layers: [
						new OlLayerTile({
							source: new TileJSON({
								url: mapUrl,
								tileSize: 512,
								crossOrigin: 'anonymous',
							}),
						}),
					],
					controls: defaultControls({attribution: false}).extend([attribution]),
					view: new OlView({
						center: fromLonLat([endPoint.long, endPoint.lat]),
						maxZoom: 20,
						zoom: 9,
					}),
				});
				setOlMap(map);

				// Init Tooltip
				const tooltip = GeoTooltip(map, tooltipRef.current);
				map.addOverlay(tooltip);
			})
		);
	}, [geoData]);

	// Effect to initialize Markers & the Polyline
	React.useLayoutEffect(() => {
		if (!olMap) return;
		//const endPoint = geoData !== null && geoData.length > 0 ? geoData[geoData.length - 1] : {long: 0, lat: 0};

		let routePoints = [];
		if (geoData !== null) {
			geoData.forEach((point, index) => {
				routePoints.push([point.long, point.lat]);
			});
		}

		const [polylineFeature] = GeoPolyline(routePoints);
		const [_clusterLayer, clusterSource] = InitGeoCluster(olMap);

		const _sourceVector = new OlSourceVector({
			features: [polylineFeature],
		});
		const _vectorLayer = new OlLayerVector({
			source: _sourceVector,
		});

		if (!!vectorLayer) olMap.removeLayer(vectorLayer);
		if (!!clusterLayer) olMap.removeLayer(clusterLayer);

		olMap.addLayer(_vectorLayer);
		setVectorLayer(_vectorLayer);

		olMap.addLayer(_clusterLayer);
		setClusterLayer(_clusterLayer);

		if (geoData !== null) AddClusterFeatures(geoData, clusterSource);

		// Fit Features on the map view
		let vectorExtent = _sourceVector.getExtent();
		if (!!vectorExtent && !(vectorExtent.includes(Infinity) || vectorExtent.includes(-Infinity))) {
			olMap.getView().fit(vectorExtent);

			const zoomLevel = olMap.getView().getZoom() > 15 ? 15 : olMap.getView().getZoom() - 0.25;
			olMap.getView().setZoom(zoomLevel);
		}
	}, [olMap, geoData]);

	// Effect to refresh the path when new geo_data point recieved
	React.useEffect(() => {
		if (newGeoPoint !== undefined && newGeoPoint !== null) {
			setGeoData(prevGeoData => [...prevGeoData, newGeoPoint]);
		}
	}, [newGeoPoint]);

	return (
		<div className="ol_map-container">
			{geoData !== null && geoData.length <= 0 ? (
				<p className="map_text">No Data</p>
			) : (
				<div ref={mapRef} className="ol_map">
					<div ref={tooltipRef} className="ol_map-popuap"></div>
				</div>
			)}
		</div>
	);
});

export default WithGeoDataSubscription(React.memo(MapComponent));
