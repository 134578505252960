import React, {Component} from 'react';
import {Trans} from 'react-i18next';
import pubsub from 'pubsub-js';
import {MultiContext} from '../../../Infrastructure/Authorization/Context/MultiContext';
import {CurrentPriceExtension, CurrentPriceProps, CurrentPriceResult} from '../../../../common/util/LicenseUtils/CurrentPriceExtension';

// Services
import LegacySensorService from '../SensorService';
import EditSensorService from '../SensorService';
import IssueProfileService from '../../../Services/IssueProfiles/IssueProfilesService';
import BillingService from '../../../Settings/Billing/BillingService';
import LicensingService from '../../../Settings/Licenses/LicensingService';

// Forms
import SensorBasicsFormComponent from '../Forms/SensorBasicsForm/SensorBasicsFormComponent';
import LimitAlarmsForm from '../Forms/LimitAlarmForm/LimitAlarmsForm';
import SensorIssuesFormComponent from '../Forms/SensorIssueForm/SensorIssuesFormComponent';
import SensorSummaryFormComponent from '../Forms/SensorSummary/SensorSummaryFormComponent.js';

// Utils
import {EditSensorSteps, EditSensorWithConfigurationTemplatSteps} from './EditSensorSteps';
import RadialSteps from '../../../Common/RadialSteps';
import RegistryHelper, {showPriceWarning} from '../Utils/RegistryHelper';
import Shared from '../../../Shared/Shared';
import Message from '../../../Shared/Components/Message';
import {cleanUpSensorBasicFormData, DiscoverModuleChannelTypeToChannelType} from '../Utils/DiscoverModule';
import FormBuilder from '../../../Forms/FormBuilder';
import {RequestLogger} from '../../../Infrastructure/Requests/Logger';
import {
	getSensorBasicsFormSettingsForReConfiguration,
	getSensorPaymentMethodSettingsForReConfiguration,
	getSensorSummarySettingsForReConfiguration,
} from '../Helpers/WizardSettingsBuilder';
import {EditSensorState} from './EditSensorState';
import {RouteChildrenProps} from 'react-router-dom';
import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';
import {IssueProfile, SensorBasicsForm, SensorBasicsFormChannel, SensorIssuesForm} from '../AddSensor/WizardState';
import {ExportFormStateAndSteps} from '../../../Wizard/Util';
import {ContentWrapper, ViewWrapper} from '../../../Layout';
import {ViewHeader} from '../../../Common';
import {SmsAddonRequired} from '../Helpers/SmsAddonRequired';
import {AlarmLimitToLimitProfile} from '../Utils/AlarmLimitToLimitProfile';
import {Button, Col, Row, Spin} from 'antd';
import {SensorService} from '../../../../common/services';
import {DiscoverModuleResponse} from '../../../../common/services/SensorService/DiscoverModuleResponse';
import {FeatureFlag} from '../../../../common/helpers';
import {GetChannelTypes} from '../Helpers/GetChannelTypes';
import {DeviceUtils} from '../../../../common/util';
import {
	GetInitialLicenseOfferingResult,
	GetLicenseOffering,
	PrepareLicenseOfferingFromLineItemTypes,
	SetUpCheckoutSession,
	SetUpSubscriptionUpdate,
	ShowSensorSettingsChangedMessage,
} from '../../../Wizard/Util/LicensingUtils';
import {getSensorStateAsync} from '../../../Wizard/Util/GetSensorStateAsync';
import {InternalProductCategories} from '../../../../common/util/LicenseUtils/LineItemConstants';

class EditSensor extends Component<RouteChildrenProps, EditSensorState> {
	static contextType = MultiContext;

	declare context: React.ContextType<typeof MultiContext>;

	constructor(props) {
		super(props);

		let current_step = 0;
		let pairingOnly = false;

		// handle sensor prepared but not started in case of
		// save configuration but start later, or pairing not successful
		if (props.location.state !== undefined && props.location.state.sensor_state === 'prepared') {
			current_step = 3;
			pairingOnly = true;
		}

		this.state = {
			formStates: null,
			issueProfiles: [],
			loading: true,
			updating: false,
			pairingOnly: pairingOnly,
			id: props.match.params.id,
			current_step: current_step,
			steps: EditSensorSteps,
			moduleForm: {
				serial_number: '',
			},
			licenseOffering: {
				baseLicenses: [],
				loggingIntervals: [],
				limitEmail1: undefined,
				limitEmail2: undefined,
				limitSms1: undefined,
				limitSms2: undefined,
				issueEmail: undefined,
				issueSms: undefined,
				retirement1: undefined,
				retirement2: undefined,
			},
			licenseOfferingResult: undefined,
			offering: null,
			hasSubscription: false,
			currentLimitAlarmsFormIndex: 0,
			sensorBasicsForm: {
				last_run_only: false,
				isConfiguration: true,
				sensor_name: '',
				customer_id: '',
				logging_interval: 600,
				unit: null,
				memo: '',
				firmware: '',
				active: false,
				moduleData: null,
				currentModuleChannels: null,
				selectedChannel: null,
				communication_interval: null,
				communication_mode: null,
				defaultSettingUnit: null,
				disableChangeLogging: null,
			},
			sensorIssuesForm: {
				editable: false,
				use_issue_alarms: false,
				issue_alarms: [],
				limit_issue_name: '',
				missing_value_alarm: false,
				missing_value_alarm_delay: 0,
				lost_measurement_alarm: false,
				missing_communication_warning: false,
				missing_communication_warning_enum: 0,
				light_warning: false,
				tilt_warning: false,
				sensor_failure_alarm: false,
				sensor_failure_alarm_delay: 0,
				radio_connection_warning: false,
				radio_connection_warning_delay: 0,
				radio_connection_warning_threshold: -90,
				low_battery_warning: false,
				email_notification: true,
				email_users: [],
				sms_notification: false,
				sms_users: [],
				threshold_option: 0,
			},
			summaryForm: {
				start_sensor: false,
				vouchers: [],
				offering: undefined,
				errorMessage: '',
				terms_of_use: false,
				disable_sensor_update_msg: '',
			},

			// Configuration
			has_additional_configuration: false,
			configuration_templates: null,
			configuration_template: {},
			additional_configuration: null,
			additional_configuration_forms: {},
		};
	}

	componentDidMount() {
		this.getModuleInformation();
	}

	getModuleInformation() {
		let gets = [
			EditSensorService.sensor(this.state.id),
			IssueProfileService.issueProfiles({}, RequestLogger.createLogData('edit-sensor', 'load-issue-profiles', 'onLoad')),
			LicensingService.getSensorLicense(this.state.id, RequestLogger.createLogData('edit-sensor', 'load-licence', 'onLoad')),
			SensorService.DiscoverModule(this.props.location.state['serial_number']),
			getSensorStateAsync(this.state.id),
		];

		Promise.all(gets).then(responses => {
			const sensorData = responses[0].data;
			const issueProfiles = responses[1].data;
			const sensorLicense = responses[2].data[0];
			const moduleData = responses[3] as DiscoverModuleResponse;
			const sensorState = responses[4];

			this.setState(prevState => ({
				summaryForm: {
					...prevState.summaryForm,
					sensorLicense: sensorLicense,
				},
			}));

			const currentChannel = moduleData.module?.used_channels?.find(
				channel => channel.sensors_id.toString() === this.state.id.toString()
			);
			const channelDescription = moduleData.module.channels.find(c => c.channel_number == currentChannel.channel_number);
			const channelType = moduleData.channel_types.find(
				c => c.channel_type == channelDescription.channel_type && c.value_index == currentChannel.value_index
			);

			const selectedChannel: SensorBasicsFormChannel = {
				channel_types: [DiscoverModuleChannelTypeToChannelType(channelType, moduleData)],
				selectedChannel: currentChannel.channel_number,
				selectedValueIndex: currentChannel.value_index,
				...currentChannel,
			};

			const currentModuleChannels = {channelsUsed: [selectedChannel], channelsFree: []};

			const sensorBasicsForm: SensorBasicsForm = {
				...this.state.sensorBasicsForm,
				last_run_only: sensorData.last_run_only,
				isConfiguration: true,
				sensor_name: sensorData.name,
				customer_id: sensorData.customer_id,
				logging_interval: sensorData.logging_interval,
				unit: sensorData.out_units_id,
				memo: sensorData.memo || '',
				firmware: sensorData.firmware || '',
				moduleData: {
					channel_types: moduleData.channel_types.map(c => DiscoverModuleChannelTypeToChannelType(c, moduleData)),
					module: {
						module_id: moduleData.module.module_id,
						channels: moduleData.module.channels,
						used_channels: moduleData.module.used_channels,
					},
				},
				currentModuleChannels: currentModuleChannels,
				selectedChannel: selectedChannel,
			};

			const sensorIssuesForm: SensorIssuesForm = {
				use_issue_alarms: sensorData.sensor_issue_alarm_id !== null,
				issue_alarm_creation_type: sensorData.sensor_issue_alarm_id || 0,
				email_notification: sensorData.sensor_issue_email_notification,
				email_users: sensorData.recipients_email_list.issue_recipients ? sensorData.recipients_email_list.issue_recipients : [],
				sms_notification: sensorData.sensor_issue_sms_notification,
				sms_users: sensorData.recipients_sms_list.issue_recipients ? sensorData.recipients_sms_list.issue_recipients : [],
				editable: sensorData.sensor_issue_alarm_id === null,
			};

			GetInitialLicenseOfferingResult(
				moduleData.module.module_id?.toString(),
				sensorData.serial_number,
				this.state.currentLimitAlarmsFormIndex,
				sensorBasicsForm,
				sensorState?.formStates?.limitAlarmForms[0],
				sensorIssuesForm
			).then(result => this.setState(prevState => ({...prevState, ...result}), this.getLicenseOfferings));

			this.setState(prevState => ({
				...prevState,
				sensorLicense: sensorLicense,
				sensor_state: sensorData.state,
				formStates: sensorState?.formStates,
				moduleForm: {
					serial_number: sensorData.serial_number,
				},
				sensorBasicsForm: sensorBasicsForm,
				sensorIssuesForm: sensorIssuesForm,
				issueProfiles: issueProfiles,
				loading: false,

				additional_configuration: sensorData.metadata || null,
			}));

			this.setState(prevState => ({...prevState, cache: prevState}));

			this.setState(prevState => prevState, this.getOffering);

			if (sensorData.metadata !== null && sensorData.metadata !== undefined) {
				if (sensorData.metadata.configuration_templates_id) this.getConfigurationTemplate();
			}
		});
	}

	getConfigurationTemplate = () => {
		EditSensorService.configuration_template(this.state.additional_configuration.configuration_templates_id).then(response => {
			this.setState({
				configuration_template: response.data.template,
				has_additional_configuration: true,
				steps: EditSensorWithConfigurationTemplatSteps,
			});

			this.mapToAdditionalConfiguration(this.state);
		});
	};

	handleSensorBasicsInputChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				[input.name]: value,
			},
		}));
	};

	handleIssueAlarmCreationTypeChange = event => {
		let that = this;

		let profile: IssueProfile = {
			limit_issue_name: '',
			missing_value_alarm: false, //Not used anymore
			missing_value_alarm_delay: 0, //Not used anymore
			lost_measurement_alarm: false,
			missing_communication_warning: false,
			missing_communication_warning_enum: 0,
			light_warning: false,
			tilt_warning: false,
			sensor_failure_alarm: false,
			sensor_failure_alarm_delay: 0,
			radio_connection_warning: false, //Not used anymore https://elproag.atlassian.net/browse/TH-460
			radio_connection_warning_delay: 0, //Not used anymore https://elproag.atlassian.net/browse/TH-460
			radio_connection_warning_threshold: -90, //Not used anymore https://elproag.atlassian.net/browse/TH-460
			low_battery_warning: false,
			editable: true,
		};

		if (event !== 0) {
			that.state.issueProfiles.forEach(function (item) {
				if (item.id === event) {
					profile = item;
					profile.editable = false;
				}
			});
		}

		that.setState(
			prevState => ({
				...prevState,
				sensorIssuesForm: {
					...prevState.sensorIssuesForm,
					issue_alarm_creation_type: event,
					limit_issue_name: profile.name,
					missing_value_alarm: profile.missing_value_alarm, //Not used anymore
					missing_value_alarm_delay: profile.missing_value_alarm_delay, //Not used anymore
					lost_measurement_alarm: profile.lost_measurement_alarm,
					missing_communication_warning: profile.missing_communication_warning,
					missing_communication_warning_enum: profile.missing_communication_warning_enum,
					light_warning: profile.light_warning,
					tilt_warning: profile.tilt_warning,
					sensor_failure_alarm: profile.sensor_failure_alarm,
					sensor_failure_alarm_delay: profile.sensor_failure_alarm_delay,
					radio_connection_warning: profile.radio_connection_warning, //Not used anymore https://elproag.atlassian.net/browse/TH-460
					radio_connection_warning_delay: profile.radio_connection_warning_delay, //Not used anymore https://elproag.atlassian.net/browse/TH-460
					radio_connection_warning_threshold: profile.radio_connection_warning_threshold, //Not used anymore https://elproag.atlassian.net/browse/TH-460
					low_battery_warning: profile.low_battery_warning,
					editable: profile.editable,
					sensors: Shared.getAffectedSensorsExcludeCurrent(profile.sensors, this.state.moduleForm.serial_number),
				},
			}),
			() => {
				this.setIssueProfileLineItems();
			}
		);
	};

	handleIssueAlarmInputChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(
			prevState => ({
				sensorIssuesForm: {
					...prevState.sensorIssuesForm,
					[input.name]: value,
				},
			}),
			() => {
				this.setIssueProfileLineItems();
				if (input.name === 'sms_notification') {
					this.getLicenseOfferings();
				}
			}
		);
	};

	makeIssueAlarmsEditable = () => {
		this.setState(prevState => ({
			...prevState,
			sensorIssuesForm: {
				...prevState.sensorIssuesForm,
				editable: !this.state.sensorIssuesForm.editable,
			},
		}));
	};

	handleUseSensorIssuesChecked = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(
			prevState => ({
				sensorIssuesForm: {
					...prevState.sensorIssuesForm,
					[input.name]: value,
					editable: value,
				},
			}),
			() => {
				this.setIssueProfileLineItems();
			}
		);
	};

	setIssueProfileLineItems = () => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			PrepareLicenseOfferingFromLineItemTypes(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel.selectedValueIndex,
				this.state.licenseOffering,
				[InternalProductCategories.IssueProfileEmailIPC, InternalProductCategories.IssueProfileSmsIPC],
				0,
				null,
				this.state.sensorIssuesForm,
				null
			).then(result => {
				this.setState(prevState => ({...prevState, licenseOffering: result}), this.getLicenseOfferings);
			});
		}
	};

	setOfferingObject = offeringObject => {
		this.setState({offering: offeringObject});
		if (offeringObject.voucher_rest > 0) {
			this.setState({
				summaryForm: {
					...this.state.summaryForm,
					disable_sensor_update_msg:
						'Update is not possible because voucher not fully redeemable (' +
						offeringObject.voucher_rest +
						' CHF remaining)' +
						'. Please use prepaid credit.',
				},
			});
		}
	};

	back = () => {
		this.updateCurrentStep(this.state.current_step - 1);

		// set terms of use and disable update warning message to false, when go back 1 step
		this.setState({
			summaryForm: {
				...this.state.summaryForm,
				disable_sensor_update_msg: '',
			},
		});
	};

	next = () => {
		if (this.state.current_step < this.state.steps.length - 1) {
			this.updateCurrentStep(this.state.current_step + 1);
		}
	};

	updateSensor = creditCard => {
		const basicForm = {
			...this.state.sensorBasicsForm,
			channel_number: this.state.sensorBasicsForm.selectedChannel ? this.state.sensorBasicsForm.selectedChannel.channel_number : null,
			value_index: this.state.sensorBasicsForm.selectedChannel ? this.state.sensorBasicsForm.selectedChannel.value_index : null,
		};

		this.setState(
			{
				sensorBasicsForm: cleanUpSensorBasicFormData(basicForm),
				updating: true,
			},
			() => {
				const payload = {
					sensor_id: this.state.id,
					PaymentInformation: {
						vouchers: this.state.summaryForm.vouchers,
						credit_card: creditCard,
						redeem_prepaid: creditCard.redeem_prepaid,
					},
					logging_interval: this.state.sensorBasicsForm.logging_interval,
					sms_notifications: SmsAddonRequired(
						this.state.moduleForm.serial_number,
						this.state.sensorBasicsForm?.selectedChannel?.selectedChannel,
						this.state.sensorBasicsForm?.selectedChannel?.selectedValueIndex,
						this.state.formStates?.limitAlarmForms,
						this.state.sensorIssuesForm
					),
					offer_total: this.state.offering === undefined ? 0 : this.state.offering.actual_price,
				};

				if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
					this.updateModule();
				} else {
					// martin: do the feature change by stripe before the module change(s)
					// if update module has returned error and license feature could still be changed before
					// changes to the license-model are apply next time, there wont be an additional booking
					// when no changes made to sms/logging_interval there will not be booking
					LicensingService.changeLicenseFeatures(
						payload,
						RequestLogger.createLogData('edit-sensor', 'change-license-feature', 'onClick')
					)
						.then(() => {
							this.updateModule();
						})
						.catch(error => Message.error('Error', 'Changes could not be saved', error))
						.finally(() => this.setState({updating: false}));
				}
			}
		);
	};

	updateModule() {
		const payload = {
			Sensor: {...this.state.sensorBasicsForm, id: this.state.id},
			SensorLimitAlarm: AlarmLimitToLimitProfile(this.state.formStates.limitAlarmForms[0]),
			SensorIssueAlarm: this.state.sensorIssuesForm,
		};

		LegacySensorService.updateModule(payload, RequestLogger.createLogData('edit-sensor', 'edit-sensor', 'onClick'))
			.then(() => {
				const successForwardUrl = '/dashboard';

				if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
					this.state.hasSubscription
						? SetUpSubscriptionUpdate(
								this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
								this.state.licenseOffering,
								successForwardUrl,
								this.props.history,
								this.context.AccessContext.user.user_settings.devicesViewSettings
						  )
						: SetUpCheckoutSession(
								this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
								this.state.licenseOffering,
								successForwardUrl,
								this.props.history,
								this.context.AccessContext.user.user_settings.devicesViewSettings
						  );
				} else {
					this.props.history.push(successForwardUrl);
					ShowSensorSettingsChangedMessage();
				}
			})
			.catch(error => {
				RegistryHelper.logPublishAndToast(error, pubsub.publish.bind(null, 'catchRegistryExceptions'), 'updating');
			})
			.finally(() => this.setState({updating: false}));
	}

	limitAlarmsFormBack = () => {
		if (this.state.currentLimitAlarmsFormIndex === 0) {
			this.back();
		} else {
			this.setState(prevState => ({currentLimitAlarmsFormIndex: prevState.currentLimitAlarmsFormIndex - 1}));
		}
	};

	limitAlarmsFormNext = () => {
		if (
			this.state.currentLimitAlarmsFormIndex ===
			GetChannelTypes(this.state.sensorBasicsForm, DeviceUtils.GetDeviceFamily(this.state.moduleForm.serial_number)).length - 1
		) {
			this.next();
		} else {
			this.setState(prevState => ({currentLimitAlarmsFormIndex: prevState.currentLimitAlarmsFormIndex + 1}));
		}
	};

	getLicenseOfferings = (redeem_prepaid?: boolean) => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			GetLicenseOffering(
				this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
				this.state.hasSubscription,
				this.state.licenseOffering
			).then(l => {
				this.setState({licenseOfferingResult: l});
			});
		} else {
			this.getOffering(redeem_prepaid);
		}
	};

	getOffering = (redeem_prepaid?: boolean, limitAlarmFormState?: LimitAlarmFormState[]) => {
		if (!this.state.pairingOnly) {
			this.setState({offering: undefined});

			let payload = {
				sensor_id: this.state.id,
				vouchers: this.state.summaryForm.vouchers,
				redeem_prepaid: redeem_prepaid ? redeem_prepaid : false,
				want_logging_interval: this.state.sensorBasicsForm.logging_interval,
				want_sms_notification: SmsAddonRequired(
					this.state.moduleForm.serial_number,
					this.state.sensorBasicsForm.selectedChannel?.selectedChannel,
					this.state.sensorBasicsForm.selectedChannel?.selectedValueIndex,
					limitAlarmFormState ? limitAlarmFormState : this.state.formStates?.limitAlarmForms,
					this.state.sensorIssuesForm
				),
			};

			BillingService.getOfferingUpgrade(payload, RequestLogger.createLogData('edit-sensor', 'load-offering-upgrade', 'onClick'))
				.then(response => {
					this.setOfferingObject(response.data);
				})
				.catch();
		} else {
			let payload = {
				logging_interval: this.state.sensorBasicsForm.logging_interval,
				serial_number: null,
				sms_active: SmsAddonRequired(
					this.state.moduleForm.serial_number,
					this.state.sensorBasicsForm.selectedChannel?.selectedChannel,
					this.state.sensorBasicsForm.selectedChannel?.selectedValueIndex,
					this.state.formStates?.limitAlarmForms,
					this.state.sensorIssuesForm
				),
				vouchers: this.state.summaryForm.vouchers,
			};

			if (this.state.moduleForm) payload.serial_number = this.state.moduleForm.serial_number;

			BillingService.getOffering(payload, RequestLogger.createLogData('edit-sensor', 'get-offering'))
				.then(response => {
					this.setOfferingObject(response.data);
				})
				.catch(function (error) {
					console.log('error ', error);
				});
		}

		// cache default offering
		this.setState({cache: {...this.state.cache, offering: this.state.offering}});
	};

	pairSensor = _ => {
		let payload = {
			_serial_nr: this.state.moduleForm.serial_number,
		};

		LegacySensorService.repairSensor(payload, RequestLogger.createLogData('edit-sensor', 'repair-sensor', 'onClick'))
			.then(_ => {
				const successForwardUrl =
					'/connectModule/' +
					this.state.id +
					'/' +
					this.state.moduleForm.serial_number +
					'/' +
					this.state.moduleForm.serial_number.substring(0, 1);

				if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
					this.state.hasSubscription
						? SetUpSubscriptionUpdate(
								this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
								this.state.licenseOffering,
								successForwardUrl,
								this.props.history,
								this.context.AccessContext.user.user_settings.devicesViewSettings
						  )
						: SetUpCheckoutSession(
								this.state.sensorBasicsForm.moduleData.module.module_id?.toString(),
								this.state.licenseOffering,
								successForwardUrl,
								this.props.history,
								this.context.AccessContext.user.user_settings.devicesViewSettings
						  );
				} else {
					this.props.history.push({
						pathname: successForwardUrl,
					});
				}
			})
			.catch(error => {
				RegistryHelper.logPublishAndToast(error, pubsub.publish.bind(null, 'catchRegistryExceptions'), 'repairing');
			});
	};

	buyAndPairSensor = creditCardObject => {
		let priceObject = this.getCurrentPriceObject();

		if (this.state.offering.actual_price !== 0) {
			showPriceWarning(priceObject, () => {
				this.pairSensor(creditCardObject);
			});
		} else {
			this.pairSensor(creditCardObject);
		}
	};

	updateCurrentStep = step => {
		this.setState({current_step: step});
		pubsub.publish('addNewSensorStepChanged', {current_step: step});
		this.getOffering();
	};

	close = e => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings.devicesViewSettings;
		this.props.history.push(DevicesViewSettings.view);
		e.preventDefault();
	};

	handleTermsOfUse = _ => {
		this.setState({
			summaryForm: {
				...this.state.summaryForm,
				terms_of_use: !this.state.summaryForm.terms_of_use,
			},
		});
	};

	mapToAdditionalConfiguration(prevState) {
		// Map Alarm limits to Display Settings Options
		const display_settings_template = prevState.configuration_template.display_settings;
		this.setState(prev => ({
			configuration_template: {
				...prev.configuration_template,
				display_settings: {
					...display_settings_template,
					fields: {
						...display_settings_template.fields,
						before_start: {
							...display_settings_template.fields.before_start,
							defaults: [...display_settings_template.fields.before_start.defaults],
						},
						in_transit: {
							...display_settings_template.fields.in_transit,
							defaults: [...display_settings_template.fields.in_transit.defaults],
						},
						after_stop: {
							...display_settings_template.fields.after_stop,
							defaults: [...display_settings_template.fields.after_stop.defaults],
						},
					},
				},
			},
		}));
	}

	handleConfigFormChange = (section, value) => {
		this.setState(prevState => ({
			additional_configuration: this.state.configuration_template[section].mapped
				? {
						...prevState.additional_configuration,
						...value,
				  }
				: prevState.additional_configuration,
		}));
	};

	handleConfigFormError(section, errors) {
		const hasErrors = Object.values(errors).some(val => val);
		this.setState(prevState => ({
			additional_configuration_forms: {
				...prevState.additional_configuration_forms,
				[`${section}IsValid`]: !hasErrors,
			},
		}));
	}

	handleConfigFormSubmit = (e, section) => {
		e.preventDefault();

		if (this.state.additional_configuration_forms[`${section}IsValid`]) {
			this.next();
		}
	};

	getCurrentPriceObject = (): CurrentPriceResult => {
		let currentPriceProps: CurrentPriceProps = {
			totalAmount: this.state.licenseOfferingResult?.totalAmount,
			actualPrice: this.state.offering?.actual_price,
			currency: this.state.licenseOfferingResult?.currency,
			interval: this.state.licenseOfferingResult?.lineItems[0]?.interval,
			isSmsDowngradeFromLicenseModel: this.state.offering?.is_sms_downgrade_from_license_model,
		};
		return CurrentPriceExtension.getCurrentPrice(currentPriceProps, this.context.FeatureFlagsContext);
	};

	saveLimitAlarmStateCallback = (limitAlarmFormState: LimitAlarmFormState) => {
		const updateState = ExportFormStateAndSteps(this.state, 0, limitAlarmFormState);

		let formStates = this.state.formStates;
		formStates.limitAlarmForms[0] = limitAlarmFormState;

		this.setState(prev => ({...prev, ...updateState, formStates: formStates}));
	};

	onLimitAlarmFormStateChange = (index: number, limitAlarmFormState: LimitAlarmFormState) => {
		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			PrepareLicenseOfferingFromLineItemTypes(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel.selectedValueIndex,
				this.state.licenseOffering,
				[InternalProductCategories.LimitProfileEmailIPC, InternalProductCategories.LimitProfileSmsIPC],
				index,
				limitAlarmFormState,
				null,
				null
			).then(result => {
				this.setState(prevState => ({...prevState, licenseOffering: result}), this.getLicenseOfferings);
			});
		} else {
			const limitAlarmsDraft = structuredClone(this.state.formStates?.limitAlarmForms);
			if (limitAlarmFormState.alarmLimit && limitAlarmFormState.recipients) limitAlarmsDraft[index] = limitAlarmFormState;
			this.getOffering(null, limitAlarmsDraft);
		}
	};

	render() {
		let loading = this.state.loading;

		return (
			<ViewWrapper>
				<ViewHeader knowledgeHelpId={'011'} heading={'Edit Sensor'}>
					<Col>
						<Button type={'primary'} onClick={this.close}>
							<em className="fsInButton elpro-Close fa-fw" />
							<span>
								<Trans i18nKey={'buttons.close'} />
							</span>
						</Button>
					</Col>
				</ViewHeader>
				<ContentWrapper>
					<Spin spinning={this.state.updating}>
						{!loading ? (
							<div style={{paddingRight: '85px'}}>
								{this.state.current_step >= 0 && (
									<RadialSteps current_step={this.state.current_step} steps={this.state.steps} uncounted_steps={0} />
								)}
								{this.state.current_step === 0 && (
									<SensorBasicsFormComponent
										hasConfigurationTemplate={false}
										lastRunOnly={this.state.sensorBasicsForm.last_run_only}
										sensorBasicsForm={this.state.sensorBasicsForm}
										handleSensorBasicsInputChange={this.handleSensorBasicsInputChange}
										currentPrice={this.getCurrentPriceObject()}
										onNext={this.next}
										sensorBasicsFormSettings={getSensorBasicsFormSettingsForReConfiguration(
											this.state.moduleForm.serial_number
										)}
									/>
								)}
								{this.state.has_additional_configuration &&
									Object.keys(this.state.configuration_template).map((sectionKey, i) =>
										this.state.configuration_template[sectionKey].visible !== false
											? this.state.current_step === this.state.configuration_template[sectionKey].editIndex && (
													<form
														key={i}
														name={`${sectionKey}Form`}
														onSubmit={e => this.handleConfigFormSubmit(e, sectionKey)}
													>
														<Col>
															<Row>
																<Col xl={12}>
																	<FormBuilder
																		schema={this.state.configuration_template[sectionKey].fields}
																		value={this.state.additional_configuration}
																		onChange={e => this.handleConfigFormChange(sectionKey, e)}
																		onError={e => this.handleConfigFormError(sectionKey, e)}
																	/>
																</Col>
															</Row>

															<Row>
																<Col>
																	<button
																		type="button"
																		className="btn btn-primary float-left"
																		onClick={this.back}
																	>
																		<em className="fsInButton elpro-Back fa-fw" />
																		<Trans i18nKey={'buttons.back'} />
																	</button>
																</Col>
																<Col>
																	<button type="submit" className="btn btn-primary float-right">
																		<em className="fsInButton elpro-Next fa-fw" />
																		<Trans i18nKey={'buttons.next'} />
																	</button>
																</Col>
															</Row>
														</Col>
													</form>
											  )
											: null
									)}

								{((!this.state.has_additional_configuration && this.state.current_step === 1) ||
									(this.state.has_additional_configuration && this.state.current_step === 2)) &&
									GetChannelTypes(
										this.state.sensorBasicsForm,
										DeviceUtils.GetDeviceFamily(this.state.moduleForm.serial_number)
									).map(
										(channelType, idx: number) =>
											idx == this.state.currentLimitAlarmsFormIndex && (
												<LimitAlarmsForm
													channelType={this.state.sensorBasicsForm.selectedChannel.channel_types.at(0)}
													currentPrice={this.getCurrentPriceObject()}
													onBack={this.limitAlarmsFormBack}
													onNext={this.limitAlarmsFormNext}
													interval={this.state.sensorBasicsForm.logging_interval}
													unit={this.state.sensorBasicsForm.unit}
													sensorIndex={null}
													saveStateCallback={limitAlarmFormState =>
														this.saveLimitAlarmStateCallback(limitAlarmFormState)
													}
													initialState={this.state.formStates?.limitAlarmForms[0]}
													moduleSerialNumber={this.state.moduleForm.serial_number}
													onFormStateChange={limitAlarmFormState =>
														this.onLimitAlarmFormStateChange(idx, limitAlarmFormState)
													}
												/>
											)
									)}
								{!this.state.has_additional_configuration && this.state.current_step === 2 && (
									<SensorIssuesFormComponent
										isLiberoG={false}
										sensorBasicsForm={this.state.sensorBasicsForm}
										sensorSerialNumber={this.state.moduleForm.serial_number}
										issueProfiles={this.state.issueProfiles}
										sensorIssuesForm={this.state.sensorIssuesForm}
										makeIssueAlarmsEditable={this.makeIssueAlarmsEditable}
										sms_checkbox_disabled={this.state.sensor_state !== 'running'}
										handleUseSensorIssuesChecked={this.handleUseSensorIssuesChecked}
										handleIssueAlarmInputChange={this.handleIssueAlarmInputChange}
										handleIssueAlarmCreationTypeChange={this.handleIssueAlarmCreationTypeChange}
										currentPrice={this.getCurrentPriceObject()}
										onBack={this.back}
										onNext={this.next}
										templateName={null}
									/>
								)}
								{this.state.current_step === this.state.steps.length - 1 && (
									<SensorSummaryFormComponent
										additional_configuration={this.state.additional_configuration}
										configuration_template={this.state.configuration_template}
										formStates={{
											...this.state.formStates,
											limitAlarmForms: [this.state.formStates?.limitAlarmForms[0]],
										}}
										getLicenseOfferings={this.getLicenseOfferings}
										getOffering={this.getOffering}
										handleTermsOfUse={this.handleTermsOfUse}
										issueProfiles={this.state.issueProfiles}
										licenseOfferingResult={this.state.licenseOfferingResult}
										moduleForm={this.state.moduleForm}
										offering={this.state.offering}
										onBack={this.state.pairingOnly ? undefined : this.back}
										onBuyAndPairSensor={this.state.pairingOnly ? this.buyAndPairSensor : undefined}
										onUpdateSensor={this.state.pairingOnly ? undefined : this.updateSensor}
										pairingOnly={this.state.pairingOnly}
										sensorBasicsForm={this.state.sensorBasicsForm}
										sensorIssuesForm={this.state.sensorIssuesForm}
										sensorPaymentMethodSettings={getSensorPaymentMethodSettingsForReConfiguration(
											this.state.moduleForm.serial_number
										)}
										sensorSummarySettings={getSensorSummarySettingsForReConfiguration(
											this.state.moduleForm.serial_number
										)}
										setOfferingObject={this.setOfferingObject}
										summaryForm={this.state.summaryForm}
									/>
								)}
							</div>
						) : (
							<div>Loading...</div>
						)}
					</Spin>
				</ContentWrapper>
			</ViewWrapper>
		);
	}
}

export default EditSensor;
