import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';

export class Measurement {
	constructor(timestamp: Date, Value: number, TiltAngle: number) {
		this.timestamp = timestamp;
		this.Timestamp = dayjs(timestamp);
		this.Value = Value;
		this.TiltAngle = TiltAngle;
	}

	private readonly timestamp: Date;
	public readonly Timestamp: dayjs.Dayjs;
	public readonly Value: number;
	public readonly TiltAngle: number | null;

	public GetDateTimeString() {
		return DateTimeUtils.setDateTimeWithOffset_date_dep(this.timestamp);
	}

	public GetTimeString() {
		return DateTimeUtils.setDateTimeWithOffset_date_dep_time(this.timestamp);
	}

	public GetDateString() {
		return DateTimeUtils.setDateTimeWithOffset_date_dep_date(this.timestamp);
	}
}
