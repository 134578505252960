import {ColDef} from 'ag-grid-enterprise';
import {Sensor} from '../Common/Types/Sensor';
import {DeviceUtils} from '../../common/util';
import {DateTimeFormatter} from './ValueFormatters';
import {
	AlarmStateGetter,
	DeviceStateGetter,
	MeasurementDateGetter,
	MeasurementValueGetter,
	RunNumberValueGetter,
	RunStartDateValueGetter,
	TiltAngleGetter,
} from './ValueGetters';
import {CONSTANTS} from '../Shared/Constants/Constants';
import {nameof} from '../../common/util/Nameof';
import {LimitAlarmStateCellClassRules} from './CellClassRules';

import styles from './styles.module.scss';
import {memo} from 'react';
import {HamburgerMenu} from './HamburgerMenu';

const metaDataCols: ColDef<Sensor>[] = Array.from({length: 7}, (_, i) => i + 1).map(idx => ({
	colId: `Metadata${idx + 1}`,
	valueGetter: m => {
		if (!m?.data) {
			return '';
		}
		if (DeviceUtils.IsLiberoGx(m.data.SerialNumber)) {
			return m.data.AdditionalConfigMetadata ? m.data.AdditionalConfigMetadata[idx] : '';
		}
		return CONSTANTS.NOT_AVAILABLE;
	},
	headerName: `Metadata ${idx + 1}`,
}));

const f = nameof<Sensor>;
const ColumnDefinitions: ColDef<Sensor>[] = [
	{
		cellClass: styles.MenuCell,
		cellRenderer: memo(HamburgerMenu),
		colId: 'Menu',
		filter: false,
		resizable: false,
		pinned: true,
		sortable: false,
		enableRowGroup: false,
		minWidth: 60,
		width: 60,
		suppressMenu: true,
		lockVisible: true,
		suppressMovable: true,
		lockPosition: 'left',
	},
	{
		colId: 'DeviceName',
		valueGetter: m => (m.data?.AdditionalConfigMetadata ? m.data.AdditionalConfigMetadata[0] : m.data?.Name),
		headerTooltip: 'Metadata 1 or Sensor Name',
		headerName: 'Device Name',
	},
	{field: f('SerialNumber'), colId: 'ModuleID'},
	{colId: 'ChannelID', headerName: 'Channel ID', valueGetter: m => m?.data?.AdditionalData['ChannelId']},
	{field: f('CustomerId'), colId: 'CustomerID', headerName: 'Customer ID'},
	{
		colId: 'State',
		headerName: 'Alarm state',
		valueGetter: AlarmStateGetter,
		cellClassRules: LimitAlarmStateCellClassRules,
	},
	{
		colId: 'MeasurementDate',
		headerName: 'Measurement Date',
		valueGetter: MeasurementDateGetter,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
	},
	{colId: 'MeasurementValue', headerName: 'Measurement Value', valueGetter: MeasurementValueGetter},
	{colId: 'DeviceState', headerName: 'Device State', valueGetter: DeviceStateGetter},
	{
		colId: 'StartDate',
		headerName: 'Start Date',
		valueGetter: RunStartDateValueGetter,
		valueFormatter: DateTimeFormatter,
		filter: 'agDateColumnFilter',
	},
	{colId: 'RunNumber', headerName: 'Run Number', valueGetter: RunNumberValueGetter},
	{colId: 'DeviceFamilyType', headerName: 'Device Family', valueGetter: m => DeviceUtils.GetDeviceFamily(m.data?.SerialNumber)},
	{colId: 'DeviceType', headerName: 'Device Type', valueGetter: m => DeviceUtils.GetDeviceType(m.data?.SerialNumber)},
	...metaDataCols,
	{
		colId: 'SensorGroups',
		headerName: 'Sensor Groups',
		valueGetter: m => {
			const groups = m?.data?.GetGroups().map(g => g.Name);
			if (groups?.length === 0) groups.push('');
			return groups;
		},
	},
];

export function getColumnDefinitions(props: {tiltEnabled: boolean}) {
	if (props.tiltEnabled && !ColumnDefinitions.find(c => c.colId === 'TiltAngle')) {
		const valueIdx = ColumnDefinitions.findIndex(c => c.colId === 'MeasurementValue') + 1;
		ColumnDefinitions.splice(valueIdx, 0, {colId: 'TiltAngle', headerName: 'Tilt Angle', valueGetter: TiltAngleGetter});
	}

	return ColumnDefinitions;
}
