import {webserverServiceProUrl} from './index';
import {getDefaultOpts} from '../index';
import {UserProfileHelper} from '../../helpers/UserProfileHelper';
import {DataGridSettings, DeviceViewSettings} from '../../types';

const baseUrl = `${webserverServiceProUrl}/api/web/users`;

export async function GetDeviceViewSettings(): Promise<DeviceViewSettings> {
	const userId = await UserProfileHelper.GetUserId();

	const response = await fetch(`${baseUrl}/${userId}/settings/devicesview`, {
		...getDefaultOpts(),
		method: 'GET',
	});

	if (response.ok) {
		var settings = (await response.json()) as unknown as DeviceViewSettings;
		return Promise.resolve(settings);
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function UpdateDevicesViewSettings(settings: DeviceViewSettings): Promise<void> {
	const userId = await UserProfileHelper.GetUserId();

	const response = await fetch(`${baseUrl}/${userId}/settings/devicesview`, {
		...getDefaultOpts(),
		body: JSON.stringify(settings),
		method: 'PUT',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function GetDataGridSetting(): Promise<DataGridSettings> {
	const userId = await UserProfileHelper.GetUserId();

	const response = await fetch(`${baseUrl}/${userId}/settings/datagrid`, {
		...getDefaultOpts(),
		method: 'GET',
	});

	if (response.ok) {
		var settings = (await response.json()) as DataGridSettings;
		return Promise.resolve(settings);
	}

	if (response.status === 404) {
		return Promise.resolve({
			filterSettings: {},
			columnSettings: [],
		});
	}

	console.error(await response.text());
	return Promise.reject();
}

export async function UpdateDataGridSettings(settings: DataGridSettings): Promise<void> {
	const userId = await UserProfileHelper.GetUserId();

	const response = await fetch(`${baseUrl}/${userId}/settings/datagrid`, {
		...getDefaultOpts(),
		body: JSON.stringify(settings),
		method: 'PUT',
	});

	if (response.ok) {
		return Promise.resolve();
	}

	console.error(await response.text());
	return Promise.reject();
}
