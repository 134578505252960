import React from 'react';
import {Descriptions, DescriptionsProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {PredictiveTrans} from '../Wizard';
import {Sensor} from '../Common/Types/Sensor';
import {FormatAsOutUnit} from '../../common/util/MeasurementValueUtils';
import {WarningIcon} from '../Common/WarningIcon';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';
import {ISensorInformation} from '@elproag/predict';

type PredictionInformationProps = {
	sensor: Sensor;
	sensorPredictiveInfo: ISensorInformation;
};

export function PredictionInformationView(props: PredictionInformationProps): React.JSX.Element {
	const [t] = useTranslation();
	const assessment = props.sensorPredictiveInfo?.assessments?.at(0);
	if (!assessment) {
		return null;
	}
	const latestAssessmentResult = assessment.assessmentResults?.at(-1);

	const items: DescriptionsProps['items'] = [
		{
			key: 'lane',
			label: `${t(PredictiveTrans.lane)}`,
			children: `${props.sensorPredictiveInfo.lane.name} (${DateTimeUtils.formatSecondsAsDuration(
				props.sensorPredictiveInfo.lane.durationInSeconds
			)})`,
		},
		{
			key: 'container',
			label: t(PredictiveTrans.container).toString(),
			children: props.sensorPredictiveInfo.container.name,
		},
	];

	try {
		items.push(
			{
				key: 'lowerLimit',
				label: t(PredictiveTrans.lowerLimit).toString(),
				children: FormatAsOutUnit(assessment.assessmentRequest.lowerLimit, props.sensor.OutUnitsId),
			},
			{
				key: 'upperLimit',
				label: t(PredictiveTrans.upperLimit).toString(),
				children: FormatAsOutUnit(assessment.assessmentRequest.upperLimit, props.sensor.OutUnitsId),
			}
		);
	} catch (e) {}

	if (latestAssessmentResult?.breachExpectedAt) {
		items.push(
			{
				key: 'breachExpectedStatus',
				label: 'Breach expected',
				children: <WarningIcon event={{isAlarm: false}} />,
			},
			{
				key: 'breachExpectedAt',
				label: 'Breach expected at',
				children: DateTimeUtils.toUserString(dayjs(latestAssessmentResult.breachExpectedAt)),
			}
		);
	}

	return <Descriptions items={items} bordered={false} column={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 4}} />;
}
