import {Event} from './Event';
import dayjs from 'dayjs';
import {EventType} from './EventType';

/**
 * Collection of events with helper classes.
 *
 * Note: JavaScript does not like extending Array<T> therefore composition was used.
 */
export class EventCollection {
	private readonly events: Event[];

	constructor(events: Event[]) {
		this.events = events;
	}

	public get Array(): Event[] {
		return this.events;
	}

	public GetEvent = (eventType: EventType): Event => this.events?.find(e => e.Type === eventType);
	private GetEventDate = (eventType: EventType): dayjs.Dayjs => this.events?.find(e => e.Type === eventType)?.DateOccurred;
	public GetDeviationEnterDate = (): dayjs.Dayjs => this.GetEventDate(EventType.DeviationEnter);
	public GetDeviationLeaveDate = (): dayjs.Dayjs =>
		this.GetEventDate(EventType.DeviationLeave) || this.GetEventDate(EventType.DeviationDeactivated);
	public GetDeviationAcknowledgedEvent = (): Event => this.events?.find(e => e.Type === EventType.DeviationAcknowledged);
}
