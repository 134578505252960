import {AuthUtils} from '../../../../common/util';

const decodeToken = token => {
	return AuthUtils.DecodeTokenToObject(token);
};

const allowedNavigationItems = (roles, menu) => {
	return menu
		.map(navigationItem => {
			if (navigationItem.allowedRoles) {
				if (navigationItem.allowedRoles.some(role => roles.includes(role))) {
					return {
						name: navigationItem.name,
						roles: navigationItem.allowedRoles,
					};
				}
			}
			return null;
		})
		.filter(e => e);
};

/**
 * @deprecated use AuthUtils.FindAccessElement instead
 * @param path
 * @param data
 */
const findAccessElement = (path, data) => {
	return AuthUtils.FindAccessElement(path, data);
};

const authUtils = {
	decodeToken,
	allowedNavigationItems,
	findAccessElement,
};

export default authUtils;
