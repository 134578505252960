import React, {useContext, useEffect, useState} from 'react';
import LiveLinkService from '../LiveLink/LiveLinkService';
import {authUtils, secureToken} from '../Infrastructure/Authorization/Utils';
import Menu from '../../Menu';
import Authentication from '../Infrastructure/Authentication/Authentication';
import {AccessDispatchContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {useTranslation} from 'react-i18next';
import {getUserProfile} from '../Settings/UserProfile/UserProfileService';
import systemSettings from '../Infrastructure/Settings/SystemSettings';
import pubsub from 'pubsub-js';
import {RequestLogger} from '../Infrastructure/Requests/Logger';
import {FeatureFlagHelper} from '../../common/helpers';
import {FeatureFlagsDispatchContext} from '../Infrastructure/Authorization/Context/FeatureFlagsContext';
import {StatusAlertProps} from './StatusAlert';
import * as PublicViewCommon from './Common';
import {PublicViewValidationComponent} from './PublicViewValidationComponent';

const LiveLink = ({...props}) => {
	const [serialForm, setSerialForm] = useState(() => {
		const {key} = props.match.params;
		const serial = key; //serialNumber ? serialNumber.split(';')[0] : undefined;
		return {serial, id: undefined};
	});
	const [t] = useTranslation();
	const setFeatureFlags = useContext(FeatureFlagsDispatchContext);

	// alert msg text
	const ALERT_TXT = {
		MESSAGE_OK: t('livelink.alert.message.ok'),
		MESSAGE_ERROR: t('livelink.alert.message.nok'),
		DESC_OK: t('livelink.alert.description.ok'),
		DESC_ERROR: t('livelink.alert.description.nok'),
		MESSAGE_ERROR_REDIRECT: t('livelink.alert.message.nok_redirect'),
		DESC_ERROR_REDIRECT: t('livelink.alert.description.nok_redirect'),
	};

	// step description text
	const STEP_DESCRIPTION = {
		0: {
			START: t('livelink.steps.description.0.start', {serial: serialForm.serial}),
			PROCESS: t('livelink.steps.description.0.process'),
			FINISH: t('livelink.steps.description.0.finish'),
			ERROR: t('livelink.steps.description.0.error'),
		},
		1: {
			START: t('livelink.steps.description.1.start'),
			PROCESS: t('livelink.steps.description.1.process'),
			FINISH: t('livelink.steps.description.1.finish'),
		},
		2: {
			START: t('livelink.steps.description.2.start'),
			PROCESS: t('livelink.steps.description.2.process'),
			FINISH: t('livelink.steps.description.2.finish'),
			ERROR: t('livelink.steps.description.2.error'),
		},
	};

	const setUserAccess = useContext(AccessDispatchContext);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentStatus, setCurrentStatus] = useState(PublicViewCommon.PROGRESS_STATUS.WAIT);
	const [disableFormElement, setDisableFormElement] = useState(true);
	const [alertProps, setAlertProps] = useState<Omit<StatusAlertProps, 'onErrorRedirect'>>({
		show: false,
		type: 'success',
		message: '',
		description: '',
	});
	const [stepDescription, setStepDescription] = useState({
		0: STEP_DESCRIPTION[0].START,
		1: STEP_DESCRIPTION[1].START,
		2: STEP_DESCRIPTION[2].START,
	});

	const delay = ms => new Promise(res => setTimeout(res, ms));

	const validateLiveLink = async () => {
		if (!serialForm.serial) {
			setDisableFormElement(false);
			return;
		}
		setCurrentStatus(PublicViewCommon.PROGRESS_STATUS.PROCESS);
		let token: string;
		try {
			token = await LiveLinkService.validateLiveLink(serialForm.serial);
		} catch (error) {
			console.error(error);
		}

		await delay(PublicViewCommon.STEP_DELAY);
		const decodedToken = authUtils.decodeToken(token);
		if (decodedToken) {
			serialForm.id = decodedToken.sensors;
			setSerialForm(serialForm);
			Authentication.setToken_f(token, true);
			setUserTimezoneOffset();
			await getAccess();
		} else {
			setCurrentStatus(PublicViewCommon.PROGRESS_STATUS.ERROR);
			setStepDescription({...stepDescription, 0: STEP_DESCRIPTION[0].ERROR});
			setDisableFormElement(false);
			setAlertProps({
				...alertProps,
				show: true,
				type: 'error',
				message: ALERT_TXT.MESSAGE_ERROR,
				description: ALERT_TXT.DESC_ERROR,
			});
		}
	};

	const setUserTimezoneOffset = () => {
		getUserProfile(RequestLogger.createLogData('login', 'load-user-profile', 'onClick'))
			.then(response => {
				Authentication.set_supports_dst_f(response.data.supports_dst);
				Authentication.set_base_utc_offset_f(response.data.base_utc_offset);
				Authentication.set_timezone_f(response.data.iana_name);
				/*
                localStorage.setItem('supports_dst', response.data.supports_dst);
                localStorage.setItem('base_utc_offset', response.data.base_utc_offset);
                localStorage.setItem('timezone', response.data.iana_name);
                */
				systemSettings
					.getTimeZoneOffset(response.data.timezone)
					.then(response => {
						//localStorage.setItem('tzOffset', response.data);
						Authentication.set_tzOffset_f(response.data);
						pubsub.publish('tzChanged', response.data);
					})
					.catch(error => console.log('error ', error));
			})
			.catch(error => console.log('error ', error));
	};

	const getAccess = async () => {
		setStepDescription({...stepDescription, 0: STEP_DESCRIPTION[0].FINISH});
		setCurrentStep(2);
		try {
			const access = await LiveLinkService.getUserAccess('liberog-livelink');
			const decodedAccessToken = authUtils.decodeToken(access.data.userAccessToken);
			const {settings} = decodedAccessToken.access_settings;
			const allowedNavigationItems = authUtils.allowedNavigationItems(settings.roles, Menu);
			const userData = {
				user: decodedAccessToken,
				authenticated: true,
				accessToken: access.data.userAccessToken,
				allowedNavigationItems: allowedNavigationItems,
			};
			secureToken.setRolesAccessToken(userData);
			setUserAccess(userData);
			setFeatureFlags(await FeatureFlagHelper.GetFeatureFlags());
			await redirectToSensorAnalysis(serialForm.id);
		} catch (error) {
			console.error(error);
			redirectToLogin();
		}
	};

	useEffect(() => {
		const initDelay = async () => {
			await delay(PublicViewCommon.INITIAL_DELAY);
			await validateLiveLink();
		};
		initDelay();
	}, []);

	const checkStatusManually = async () => {
		setDisableFormElement(true);
		setStepDescription({...stepDescription, 0: STEP_DESCRIPTION[0].START});
		setAlertProps({...alertProps, show: false});

		await validateLiveLink();
	};

	const redirectToSensorAnalysis = async id => {
		setAlertProps({
			...alertProps,
			show: true,
			type: 'success',
			description: ALERT_TXT.DESC_OK,
			message: ALERT_TXT.MESSAGE_OK,
		});
		await delay(PublicViewCommon.REDIRECT_DELAY);
		props.history.push({
			pathname: '/sensorAnalysis/' + id,
			state: {checkLastRunOnly: false},
		});
	};

	const redirectToLogin = () => {
		props.history.push('/login');
	};

	const onKeyChanged = e => {
		const serialValue = e.target.value.split(';')[0];
		setSerialForm({...serialForm, serial: serialValue});
	};

	return (
		<>
			<PublicViewValidationComponent
				alertProps={alertProps}
				checkStatusManually={checkStatusManually}
				currentStatus={currentStatus}
				currentStep={currentStep}
				description={t('livelink.description')}
				disableFormElement={disableFormElement}
				identifier={serialForm.serial}
				onIdentifierChanged={onKeyChanged}
				placeholder="Live-Link Key"
				stepDescription={stepDescription}
				title={t('livelink.title')}
			/>
		</>
	);
};

export default LiveLink;
